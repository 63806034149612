var clickManager = {

  clickToSection: function(section_id){
    var divMenuTop = $('#menu-for-fullpages');

    if(!$(section_id).hasClass('is_visible')){

      var currentSectionVisible = $(".div-section .div-wrap-content.is_visible");

      if(currentSectionVisible.length > 0){
        currentSectionVisible.each(function() {
          $(this).removeClass("is_visible");
        });
      }

      var sectionToShowContent = $(section_id).find(".div-wrap-content");

      sectionToShowContent.addClass("is_visible");
    }

    divMenuTop.foundation('scrollToLoc', section_id);
    divMenuTop.foundation('reflow');
  }

};

$(document).on("click", ".link-to-section, .link-nav", function(e){
  e.preventDefault();
  e.stopPropagation();

  clickManager.clickToSection($(this).attr('href'));
});

$(document).on("click", ".div-wrap-content-title", function(e){
  e.preventDefault();
  e.stopPropagation();

  clickManager.clickToSection($(this).data('section-id'));
});
