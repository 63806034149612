$(function(){

  Vue.transition('animation-list-projects', {
    enterClass: 'slideInUp',
    leaveClass: 'slideOutDown'
  });

 /* var vueManager = {

    _animationEnd: 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',

    _anmiatedInClassDivYear: 'rollIn',
    _anmiatedOutClassDivYear: 'rollOut',

    _vue_list_projects: new Vue({
      el: ".div-content-list-projects",

      data: {
        projects: [],

        show_div_list_projects: false
      },

      ready:function(){
        //this.fetch_projects();
      },

      methods:{
        fetch_projects: function(projects_year){
          var url = '/projects_by_year/' + projects_year + '/';
          var params = { };

          // GET request
          this.$http.get(url, params).then(function (response) {
            // success callback
            this.projects = response.data;
            this.show_div_list_projects = true;

          }, function (response) {
            show_div_list_projects = false;
          });

        }
      }

    })

  };
*/
  $(document).on("click", ".div-hex-project", function(e){
    e.stopPropagation();
    e.preventDefault();

    var link = $(this);
    var selected_year = link.data("year");

    var divs_projects_preview_not_selected = $(".div-hex-project[data-year!='"+ selected_year +"']");

    if(divs_projects_preview_not_selected.length > 0){
      $(divs_projects_preview_not_selected).each(function() {
        var project_preview = $(this);

        project_preview.addClass(vueManager._anmiatedOutClassDivYear).one(vueManager._animationEnd, function() {
          $(this).removeClass(vueManager._anmiatedOutClassDivYear);
          $(this).addClass("hide");
        });
      });
    }
    setTimeout(function(){
      link.addClass("selected");
      vueManager._vue_list_projects.fetch_projects(selected_year);
    }, 750);



    $('html, body').animate({
        scrollTop: $("#section_portfolio").offset().top
    }, 1000);
  });

  $(document).on("click", ".link-back-list-projects", function(e){
    e.preventDefault();
    e.stopPropagation();

    vueManager._vue_list_projects.show_div_list_projects = false;

    var divs_projects_preview = $(".div-hex-project");

    if(divs_projects_preview.length > 0){
      divs_projects_preview.each(function(){
        var project_preview = $(this);

        if(project_preview.hasClass("selected")){
          project_preview.removeClass("selected");
        }

        if(!project_preview.is(":visible")){
          $(this).removeClass("hide");
          project_preview.addClass(vueManager._anmiatedInClassDivYear).one(vueManager._animationEnd, function() {
            $(this).removeClass(vueManager._anmiatedInClassDivYear);
          });;
        }
      });
    }
  });

});
