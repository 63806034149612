$(function(){
  $(document).foundation();

  $(".containerCarouselAboutText").owlCarousel({
      items:1,
      loop:true,
      margin:0,
      autoplay: false,
      autoplayTimeout:5000,
      dots: true,
      mouseDrag: true,
      autoHeight: true
  });

  setTimeout(function(){ $(".containerCarouselAboutText").trigger('refresh.owl.carousel'); }, 1000);

  $(document).on('click', '.linkScrollToElem', function(e){
    e.preventDefault();
    e.stopPropagation();

    var elemIdToScroll = $(this).attr('href');

    if(elemIdToScroll && $(elemIdToScroll).length > 0){
      $('html,body').animate({
        scrollTop: $(elemIdToScroll).offset().top
      }, 2000);
    }
  });
});
